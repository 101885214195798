export default [
    { id: "AB", name: "Alberta" },
    { id: "BC", name: "British Columbia" },
    { id: "MB", name: "Manitoba" },
    { id: "NB", name: "New Brunswick" },
    { id: "NL", name: "Newfoundland and Labrador" },
    { id: "NS", name: "Nova Scotia" },
    { id: "ON", name: "Ontario" },
    { id: "PE", name: "Prince Edward Island" },
    { id: "QC", name: "Quebec" },
    { id: "SK", name: "Saskatchewan" },
    { id: "NT", name: "Northwest Territories" },
    { id: "NU", name: "Nunavut" },
    { id: "YT", name: "Yukon" }
]
