import moment from "moment"
import numeral from "numeral"

const language_utilities = {
    methods: {
        langugage_reread () {
            const language = this.$cookie.get("lang") || "fr"
            this.$nextTick(() => {
                this.$i18n.set(language)
                moment.locale(language)
                numeral.locale(language)
            })
        }
    }
}

export default language_utilities
