import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

const grades = [...Array(13)].map((noop, index) => index)

const handles_grades = {
    mixins: [addl_profile_utilities],
    methods: {
        grade_by_id (id) {
            return this.raw_grades[id] || {}
        }
    },
    computed: {
        raw_grades () {
            return grades.map(grade => {
                if(!grade) return { id: grade, abbreviation: this.$root.translate("K"), name: this.$root.translate("Kindergarten") }
                if(grade === 1) return { id: grade, abbreviation: `${grade}`, name: this.$root.translate("Grade 1") }
                return { id: grade, abbreviation: `${grade}`, name: this.$root.translate("Grade {n}", { n: grade }) }
            })
        },
        available_grades () {
            const profile = this.profile
            if(!profile || !profile.max_grade) return this.raw_grades
            return this.raw_grades.filter(grade => grade.id <= profile.max_grade)
        }
    }
}

export default handles_grades
