import { createToken, createPaymentMethod } from "vue-stripe-elements-plus"

import handles_subscription from "@/custom/mixins/HandlesSubscription"

import CouponCodeInput from "@/custom/components/Inputs/CouponCodeInput"
import CreditCardInput from "@/custom/components/Inputs/CreditCardInput"

const handles_payments = {
    mixins: [handles_subscription],
    components: {
        CouponCodeInput,
        CreditCardInput
    },
    methods: {
        tokenize_payment (user, existing_card_last_four = "") {
            return new Promise((resolve, reject) => {
                const billing_details = user ? {
                    name: `${user.first_name} ${user.last_name}`,
                    email: user.email,
                    address: {
                        line1: user.line1,
                        line2: user.line2,
                        city: user.city,
                        state: user.state,
                        country: user.country,
                        postal_code: user.zip
                    }
                } : {}
                if(this.proceed_without_payment_info) resolve({ gateway_token: "", billing_details })
                else {
                    createToken().then(data => {
                        if(data.error) {
                            if((data.error.code === "incomplete_number") && !!existing_card_last_four) resolve({ gateway_token: "", billing_details })
                            else reject(data.error)
                        } else if(data.token) {
                            createPaymentMethod("card", {
                                card: data.token.card.id,
                                billing_details
                            }).then(data => {
                                if(data.error) reject(data.error.message)
                                else if(data.paymentMethod) resolve({ gateway_token: data.paymentMethod.id, billing_details })
                                else reject(this.$root.translate("Payment Method error"))
                            })
                        } else reject(this.$root.translate("No token returned"))
                    })
                }
            })
        },
        save_payment_data (value, field) {
            this[field] = value
        }
    },
    computed: {
        proceed_without_payment_info () {
            return !this.owed_amount
        },
        owed_amount () {
            return this.plan_price(this.plan, this.coupon, this.taxes)
        }
    }
}

export default handles_payments
