import { mapGetters } from "vuex"

import misc_utilities from "@/nibnut/mixins/MiscUtilities"
import subscription_utilities from "@/custom/mixins/SubscriptionUtilities"

const handles_subscription = {
    mixins: [misc_utilities, subscription_utilities],
    mounted () {
        this.load_plans()
        this.load_tax_rates()
        this.load_coupon()
    },
    watch: {
        invitation_token: "load_plans",
        country: "load_tax_rates",
        coupon_code: "load_coupon"
    },
    methods: {
        load_plans () {
            this.$store.dispatch(
                "FETCH_RECORDS",
                {
                    entity: "plan",
                    query: {
                        invitation_token: this.invitation_token,
                        fields: ["fieldset::picker"]
                    }
                }
            ).catch(error => {
                console.error(error.message)
            })
        },
        load_tax_rates () {
            if(this.country) {
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "tax_rate",
                        query: {
                            jurisdiction: this.country
                        }
                    }
                )
            }
        },
        load_coupon () {
            if(this.coupon_code) {
                this.$store.dispatch(
                    "REBATE",
                    { code: this.coupon_code, passthru: false }
                ).catch(error => {
                    if(!this.coupon_auto_applied) this.$error(error.message)
                    else console.error(error.message)
                })
            }
        }
    },
    computed: {
        ...mapGetters(["entity_records", "entity_record"]),
        plan () {
            return this.entity_record("plan", this.setting("default_plan_id")) || {}
        },
        coupon () {
            if(!this.coupon_code) return null
            return this.entity_records("coupon").find(coupon => coupon.stripe_id === this.coupon_code)
        },
        taxes () {
            if(!this.country || !this.province) return []

            return this.entity_records("tax_rate").filter(tax_rate => {
                return (tax_rate.jurisdiction === this.country) && (tax_rate.provinces.indexOf(this.province) >= 0)
            })
        },
        unit_price () {
            return this.stripe_plan_price(this.plan, this.plan_price(this.plan, this.coupon), this.translate("student:::students", {}, 1))
        },
        regular_unit_price () {
            return this.stripe_plan_price(this.plan, this.plan_price(this.plan), this.translate("student:::students", {}, 1))
        },
        coupon_auto_applied () {
            const auto_apply_coupon_code = this.setting("auto_apply_coupon_code")
            return !!auto_apply_coupon_code && (this.coupon_code === auto_apply_coupon_code)
        }
    },
    data () {
        return {
            qty: 1,
            coupon_code: null,
            invitation_token: null,
            country: "CA",
            province: ""
        }
    }
}

export default handles_subscription
