const tracks = {
    methods: {
        track_event (action, event_category, event_label, value = null) {
            const data = {
                event_category,
                event_label
            }
            if(value !== null) data.value = value
            window.gtag("event", action, data)
        }
    }
}

export default tracks
