import { mapState } from "vuex"

import profile_utilities from "@/nibnut/mixins/ProfileUtilities"

const addl_profile_utilities = {
    mixins: [profile_utilities],
    methods: {
        role_is_student (role) {
            return (role === this.constants("roles", "ROLE_STUDENT").id)
        },
        role_is_teacher (role) {
            return (role === this.constants("roles", "ROLE_TEACHER").id)
        },
        license_by_user_id (user_id) {
            return this.entity_records("license").find(license => license.user_id === user_id) || {}
        },
        group_users_by_user_id (user_id) {
            return this.entity_records("group_user").filter(group_user => group_user.user_id === user_id)
        },
        group_users_by_group_id (group_id) {
            return this.entity_records("group_user").filter(group_user => group_user.group_id === group_id)
        },
        save_profile_ip (field) {
            if(!!this.profile && (!!this.profile.institution_id || this.is_at_least_administrator)) {
                this.$store.dispatch("RECORD_SAVE", {
                    entity: "user",
                    id: this.profile_id,
                    data: { [field]: this.ip, fields: ["fieldset::profile"] }
                }).catch(error => {
                    this.$error(error.message)
                })
            }
        }
    },
    computed: {
        ...mapState(["ip"]),
        is_student () {
            if(!this.profile_id) return false
            return this.role_is_student(this.profile.role)
        },
        is_teacher () {
            if(!this.profile_id) return false
            return this.role_is_teacher(this.profile.role)
        },
        is_at_least_teacher () {
            return this.is_teacher || this.is_administrator || this.is_developer
        },
        profile_license () {
            if(!this.profile_id) return []
            return this.license_by_user_id(this.profile_id)
        },
        profile_group_users () {
            if(!this.profile_id) return []
            return this.group_users_by_user_id(this.profile_id)
        },
        profile_editable_groups () {
            const min_role = this.constants("group_user_types", "ROLE_COLLABORATOR").id
            return this.profile_group_users.filter(group_user => {
                return group_user.role >= min_role
            })
        },
        profile_editable_group_ids () {
            return this.profile_editable_groups.map(group_user => group_user.group_id)
        },
        profile_is_subscribed () {
            if(!this.profile) return false
            return this.is_at_least_administrator || !!this.profile.is_subscribed
        },
        unknown_ip () {
            if(!this.profile || !this.profile.ips || !this.profile.institution_id) return false
            return !this.valid_ip && (this.profile.ips.invalid.indexOf(this.ip) < 0)
        },
        valid_ip () {
            if(!this.profile || !this.profile.ips || !this.profile.institution_id) return false
            return (this.profile.ips.valid.indexOf(this.ip) >= 0)
        }
    }
}

export default addl_profile_utilities
