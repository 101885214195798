import string_utilities from "@/custom/mixins/StringUtilities"

const subscription_utilities = {
    mixins: [string_utilities],
    methods: {
        add_taxes_to_price (taxes, price) {
            const base_price = price
            if(taxes && taxes.length) {
                taxes.forEach(tax_rate => {
                    price += this.tax_amount_for_price(base_price, tax_rate)
                })
            }
            return Math.round(price)
        },
        plan_price (plan, coupon, taxes) {
            let price = plan ? plan.amount : 0
            if(coupon) {
                if(coupon.amount_off) price -= coupon.amount_off
                else price -= Math.round(price * (coupon.percent_off / 100 / 100))
                if(price < 0) price = 0
            }
            return this.add_taxes_to_price(taxes, price)
        },
        tax_amount_for_price (price, tax_rate) {
            return (price * (tax_rate.percentage / 100))
        }
    }
}

export default subscription_utilities
