import CustomPage from "@/custom/components/CustomPage"

const is_page = {
    components: {
        Page: CustomPage
    },
    methods: {
        statused (status) {
            this.page_status = status
            this.waiting = false
        },
        page_autoscroll () {
            if(!!this.$route.meta && !!this.$route.meta.scroll_position && (this.$route.meta.scroll_position !== true)) {
                setTimeout(() => {
                    window.scrollTo({ top: this.$route.meta.scroll_position.y, left: this.$route.meta.scroll_position.x })
                }, 250)
            }
        }
    },
    data () {
        return {
            page_status: 200,
            waiting: true
        }
    }
}

export default is_page
