import core_string_utilities from "@/nibnut/mixins/StringUtilities"

const string_utilities = {
    mixins: [core_string_utilities],
    methods: {
        translate_url (urls) {
            const lang = ["en", "fr"].indexOf(this.$cookie.get("lang") || "en")
            const translations = urls.split(";")
            if(!translations.length || (lang < 0) || (lang >= translations.length)) return urls
            return translations[lang]
        },
        coupon_description (coupon, unit_name, interval, interval_count, components = ["discount", "duration"]) {
            const description = []

            if(components.indexOf("discount") >= 0) {
                const off = coupon.amount_off
                    ? this.nibnut_filter("nibnut.currency", [coupon.amount_off / 100])
                    : this.nibnut_filter("nibnut.percent", [coupon.percent_off / 100])
                description.push(
                    `${off}${this.stripe_duration_units(unit_name, interval, interval_count)}`
                )
            }
            if(components.indexOf("duration") >= 0) {
                description.push(
                    this.translate("Your first{unit}, then at regular price.", { unit: this.stripe_duration_units("", interval, interval_count, " ", false) })
                )
            }
            return description.join(" ")
        }
    }
}

export default string_utilities
