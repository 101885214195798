import { mapGetters } from "vuex"

const has_translatable_fields = {
    methods: {
        field_translation_for_classname (classname, entity_id, field_name) {
            let translation
            if(!entity_id) {
                if(this.held_translations[classname]) translation = this.held_translations[classname][field_name]
            } else {
                translation = this.entity_records("field_translation").find(field_translation => {
                    return (field_translation.entity_type === classname) && (field_translation.entity_id === entity_id)
                })
            }
            return translation || { id: 0, entity_type: classname, entity_id, field_name, value: "" }
        },
        save_field_translation_for_classname (classname, entity_id, field_name, value) {
            const translation = this.field_translation_for_classname(classname, entity_id, field_name)
            if(!entity_id) {
                if(!this.held_translations[classname]) this.$set(this.held_translations, classname, {})
                this.$set(this.held_translations[classname], field_name, { id: 0, entity_type: classname, entity_id, field_name, value })
                return Promise.resolve(this.field_translation_for_classname(classname, entity_id, field_name))
            }
            if(translation.id) return this.save_field_for_record_id("field_translation", translation.id, value, "value")
            else {
                return this.$store.dispatch(
                    "CREATE_RECORD",
                    {
                        entity: "field_translation",
                        data: {
                            entity_type: classname,
                            entity_id,
                            field_name,
                            value
                        }
                    }
                )
            }
        },
        field_translation (field_name) {
            return this.field_translation_for_classname(this.classname, this.record_id, field_name)
        },
        save_field_translation (field_name, value) {
            return this.save_field_translation_for_classname(this.classname, this.record_id, field_name, value)
        }
    },
    computed: {
        ...mapGetters(["entity_records"])
    },
    data () {
        return {
            classname: "",
            relation_ids: ["field_translation"],
            held_translations: {}
        }
    }
}

export default has_translatable_fields
